import React, { useState } from "react"
import PropTypes from "prop-types"
import tw, { styled } from "twin.macro"
import { AnimatePresence, motion } from "framer-motion"
import parse from "html-react-parser"

const Section = styled.section`
  ${tw`relative z-40 py-c120 md:py-c96 2xl:py-c120`}

  & + section.small-cards-section {
    z-index: 39;
  }
  & + section.small-cards-section + section.small-cards-section {
    z-index: 38;
  }
  &
    + section.small-cards-section
    + section.small-cards-section
    + section.small-cards-section {
    z-index: 37;
  }
  &
    + section.small-cards-section
    + section.small-cards-section
    + section.small-cards-section
    + section.small-cards-section {
    z-index: 36;
  }
`
const Teasers = styled.div`
  ${tw`relative grid gap-0 sm:grid-cols-2 lg:grid-cols-4`}
`
const HeadlineWrap = styled.div`
  ${tw`relative z-10 font-black font-ubuntu pb-c96 px-offset sm:px-12 lg:px-16 xl:px-24`}
`
const SingleColumn = styled(motion.div)`
  ${tw`relative cursor-pointer`}
`
const TeaserBox = styled(motion.div)`
  ${tw`relative pt-c25 pb-c25`}
  cursor: ${props => (props.accordion ? "pointer" : "default")};
`
const TeaserTitle = styled(motion.p)`
  ${tw`relative flex items-center justify-center font-bold text-center uppercase font-ubuntu text-20 md:text-24 xl:text-30 pb-c15 px-offset h-c120 my-c60`}
`
const TeaserDescriptionWrap = styled(motion.div)`
  ${tw`relative `}
  a {
    ${tw`underline`}
  }
`
const TeaserDescription = styled(motion.p)`
  ${tw`relative font-normal text-center transition-all duration-500 ease-in-out font-firacode text-14 md:text-16 px-offset`}
`
const Arrow = styled(motion.span)`
  ${tw`absolute block font-bold transform bottom-2 right-2 font-firacode text-24`}
`

const WpAcfSmallCardsModuleBlock = ({ moduleData }) => {
  const blockData = moduleData.acfSmallCardsBlock
  const sectionAttributes = moduleData.attributes
  const sectionId = sectionAttributes.anchor || ""

  const [expanded, setExpanded] = useState([])
  const [hovered, setHovered] = useState([])

  return (
    <Section
      id={sectionId}
      style={{ backgroundColor: blockData.backgroundColor }}
      className="small-cards-section"
    >
      <HeadlineWrap
        style={{
          color: blockData.headlineColor || "#000",
        }}
        className="relative w-2/3 font-bold headline-section font-ubuntu"
      >
        <span
          className="relative"
          data-start-symbol={blockData.startSymbol}
          data-end-symbol={blockData.endSymbol}
        >
          {parse(blockData.headline)}
        </span>
      </HeadlineWrap>
      <SingleColumn>
        <Teasers>
          {blockData.cards.map((item, index) => {
            return (
              <TeaserItem
                key={`smc-${index}`}
                index={index}
                trimWords={15}
                title={item.cardHeadline}
                headlineColor={item.headlineColor}
                cardBackground={item.cardBackground}
                description={item.cardDescription}
                descriptionColor={item.descriptionColor}
                expanded={expanded}
                setExpanded={setExpanded}
                hovered={hovered}
                setHovered={setHovered}
                accordion={!!blockData.accordion}
              />
            )
          })}
        </Teasers>
      </SingleColumn>
    </Section>
  )
}

const TeaserItem = ({
  index,
  title,
  description,
  expanded,
  setExpanded,
  trimWords,
  cardBackground,
  headlineColor,
  descriptionColor,
  hovered,
  setHovered,
  accordion,
}) => {
  const transition = {
    duration: 0.8,
    ease: [0.04, 0.62, 0.23, 0.98],
  }
  const arrowVariantsMain = {
    open: {
      display: "none",
      rotate: -90,
      transition: transition,
    },
    init: {
      rotate: -270,
      display: "block",
      transition: transition,
    },
    hover: {
      y: ["2px", "-2px"],
      transition: {
        type: "tween",
        yoyo: Infinity,
        duration: 0.3,
        ease: [0.04, 0.62, 0.23, 0.98],
      },
    },
  }
  const arrowVariants = {
    open: {
      rotate: -90,
      transition: transition,
    },
    init: {
      rotate: -270,
      transition: transition,
    },
  }
  const teaserVariants = {
    open: {
      display: "block",
      height: "auto",
      transition: transition,
    },
    init: {
      display: "none",
      height: 0,
      transition: {
        type: "tween",
        duration: 0.4,
        ease: [0.04, 0.62, 0.23, 0.98],
      },
    },
  }

  const truncate = (string, words) => {
    // Step 1: Replace content between < and > with placeholders
    const placeholders = []
    const modifiedString = string.replace(/<[^>]*>/g, match => {
      placeholders.push(match)
      return `PLACEHOLDER${placeholders.length - 1}`
    })

    // Step 2: Perform truncation
    const truncated = modifiedString.split(" ").splice(0, words).join(" ")

    // Step 3: Replace placeholders with original content
    const result = truncated.replace(
      /PLACEHOLDER(\d+)/g,
      (match, index) => placeholders[index]
    )

    return result
  }
  const truncateRest = (string, words) => {
    // Step 1: Replace content between < and > with placeholders
    const placeholders = []
    const modifiedString = string.replace(/<[^>]*>/g, match => {
      placeholders.push(match)
      return `PLACEHOLDER${placeholders.length - 1}`
    })
    // Step 2: Perform truncation
    const truncated = modifiedString
      .split(" ")
      .splice(words, string.length)
      .join(" ")

    // Step 3: Replace placeholders with original content
    const result = truncated.replace(
      /PLACEHOLDER(\d+)/g,
      (match, index) => placeholders[index]
    )

    return result
  }
  const getIndex = index => {
    if (index === 0) {
      return "z-40"
    }
    if (index === 1) {
      return "z-30"
    }
    if (index === 2) {
      return "sm:order-4 lg:order-none z-20"
    }
    if (index === 3) {
      return "sm:order-3 lg:order-none"
    }
    return "order-none z-10"
  }

  return (
    <TeaserBox
      style={{
        backgroundColor: cardBackground,
      }}
      accordion={accordion}
      className={getIndex(index)}
      initial={false}
      whileHover="hover"
      onHoverStart={() =>
        setHovered(currentHovered => {
          return [...currentHovered, index]
        })
      }
      onHoverEnd={() =>
        setHovered(currentHovered => {
          return currentHovered.filter(item => item !== index)
        })
      }
      onClick={event => {
        // Check if the click was inside an <a> tag
        let target = event.target
        while (target != null) {
          if (target.tagName === "A") {
            // If an <a> tag was clicked, do nothing
            return
          }
          target = target.parentNode
        }

        // Proceed with the original logic if no <a> tag was clicked
        setExpanded(currentExpanded => {
          return expanded.includes(index)
            ? currentExpanded.filter(item => item !== index)
            : [...currentExpanded, index]
        })
      }}
      key={`textteaser-${index}`}
    >
      <TeaserTitle style={{ color: headlineColor }}>{title}</TeaserTitle>
      <AnimatePresence initial={false}>
        <TeaserDescriptionWrap key={`descwrap-${index}`}>
          <TeaserDescription
            style={{
              color: descriptionColor,
            }}
          >
            {accordion ? (
              <>
                {parse(truncate(description, trimWords))}
                {!expanded.includes(index) && <span>...</span>}
                <motion.span
                  variants={teaserVariants}
                  initial="init"
                  animate={expanded.includes(index) ? "open" : "init"}
                  exit="init"
                  style={{
                    backgroundColor: cardBackground,
                  }}
                  className="absolute left-0 right-0 block overflow-hidden px-offset pb-c20"
                >
                  {parse(truncateRest(description, trimWords))}
                  <Arrow
                    style={{ color: headlineColor }}
                    initial="init"
                    animate={
                      expanded.includes(index)
                        ? "open"
                        : hovered.includes(index) && !expanded.includes(index)
                        ? "hover"
                        : "init"
                    }
                    exit="init"
                    variants={arrowVariants}
                  >{`->`}</Arrow>
                </motion.span>
              </>
            ) : (
              description
            )}
          </TeaserDescription>
        </TeaserDescriptionWrap>
        {accordion && (
          <Arrow
            style={{ color: headlineColor }}
            initial="init"
            animate={
              expanded.includes(index)
                ? "open"
                : hovered.includes(index) && !expanded.includes(index)
                ? "hover"
                : "init"
            }
            exit="init"
            variants={arrowVariantsMain}
          >{`->`}</Arrow>
        )}
      </AnimatePresence>
    </TeaserBox>
  )
}

WpAcfSmallCardsModuleBlock.propTypes = {
  moduleData: PropTypes.object,
}

export default WpAcfSmallCardsModuleBlock
